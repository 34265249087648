<template>
  <v-container>
    <v-row>
      <v-col>
        <v-col class="d-flex justify-center align-center">
          <EstoreCard />
        </v-col>

        <v-col class="d-flex justify-center align-center">
          <v-img
            src="img/event/hikasai.jpg"
            max-width="30vw"
            min-width="400px"
            @click="redirectToUrl"
          />
        </v-col>

        <v-chip
          color="white"
          label
          @click="filterEventsByDC('All')"
        >
          All
        </v-chip>
        <v-chip
          color="red"
          label
          text-color="white"
          @click="filterEventsByDC('Mana')"
        >
          Mana
        </v-chip>
        <v-chip
          color="blue"
          label
          text-color="white"
          @click="filterEventsByDC('Elemental')"
        >
          Elemental
        </v-chip>
        <v-chip
          color="green"
          label
          text-color="white"
          @click="filterEventsByDC('Gaia')"
        >
          Gaia
        </v-chip>
        <v-chip
          color="orange"
          label
          text-color="white"
          @click="filterEventsByDC('Meteor')"
        >
          Meteor
        </v-chip>
        <v-chip
          color="purple"
          label
          text-color="white"
          @click="filterEventsByDC('配信')"
        >
          配信
        </v-chip>
        <v-chip
          color="brown"
          label
          text-color="white"
          @click="filterEventsByDC('その他')"
        >
          その他
        </v-chip>

        <v-toolbar
          flat
        >
          <v-btn
            v-if="type == 'day'"
            fab
            variant="text"
            size="small"
            color="grey-darken-2"
            @click="prev"
          >
            <v-icon size="small">
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            v-if="type == 'day'"
            fab
            variant="text"
            size="small"
            color="grey-darken-2"
            @click="next"
          >
            <v-icon size="small">
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer />
          <v-menu location="bottom end">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                variant="outlined"
                color="grey-darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon end>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'custom-daily'">
                <v-list-item-title>3days</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>day</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :masks="{ title: 'YYYY MMM', L: 'YYYY-MM-DD',}"
            :events="events"
            :event-color="getEventColor"
            start="2023-08-10"
            end="2023-08-12"
            :type="type"
            locale="ja-jp"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          >
            <template v-slot:day-body="{ date, week }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              />
            </template>
          </v-calendar>
          <event-dialog
            v-model="dialog"
            :selected-event="selectedEvent"
            :formatted-text="formattedText"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <h2 style="text-align: center;">
      フォトコンテスト同時開催中!
      <router-link
        :to="{ name: 'EventPhoto'}"
      >
        <v-btn
          color="primary"
          rounded
        >
          作品一覧はこちらから
        </v-btn>
      </router-link>
    </h2>

    <tweet
      :id="id_1"
      error-message=" "
      :options="{ align: 'center' }"
      @TweetError="notShow(item)"
    >
      <spinner />
    </tweet>

    <h2 style="text-align: center;">
      ポスター一覧
    </h2>

    <div
      v-masonry
      transition-duration="0.3s"
      item-selector=".item"
      class="masonryWrap"
      :origin-top="true"
      :horizontal-order="false"
    >
      <div
        v-for="(item, index) in uniqueImagesEvents"
        :key="index"
      >
        <div
          v-for="(i, ind) in item.unique_image_urls"
          :key="ind"
          v-masonry-tile
          class="item col-6 col-md-4"
        >
          <img
            :src="i"
            width="100%"
            :alt="item.name"
            class="item-image"
            @click="openDialog(i,item)"
          >
        </div>
      </div>
    </div>

    <event-dialog
      v-model="dialog2"
      :selected-event="imgSelectedEvent"
      :formatted-text="imgFormattedText"
    />

    <div />
  </v-container>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue'

  const calendar = ref()

  const ready = ref(false)

  const cal = computed(() => {
    return ready.value ? calendar.value : null
  })

  onMounted(() => {
    ready.value = true
    scrollToTime()
    updateTime()
  })

  function getCurrentTime () {
    return cal.value ? cal.value.times.now.hour * 60 + cal.value.times.now.minute : 0
  }
  function scrollToTime () {
    const time = getCurrentTime()
    const first = Math.max(0, time - (time % 30) - 30)
    cal.value.scrollToTime(first)
  }
  function updateTime () {
    setInterval(() => cal.value.updateTimes(), 60 * 1000)
  }
</script>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import Spinner from 'vue-simple-spinner'
  import axios from 'axios'
  import EventDialog from '../components/EventDialog.vue'

  export default {
    name: 'EventView',
    // SEO関連情報
    metaInfo: {
      title: 'Event',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    components: {
      Tweet,
      Spinner,
      EventDialog,
    },
    data: function () {
      return {
        targetDate: '',
        events: [],
        events_tmp: [],
        focus: '',
        selectedEvent: {},
        type: 'custom-daily',
        selectedElement: null,
        selectedOpen: false,
        dialog: false,
        typeToLabel: {
          'custom-daily': '3days',
          day: 'day',
        },
        id_1: '1683028234022313984',
        max: '',
        dialog2: false, // ダイアログの表示状態を制御
        selectedImage: '', // 選択された画像のURL
        eventName: '',
        imgSelectedEvent: {},
        imgFormattedText: '',
        value: '',
        ready: false,
      }
    },
    computed: {
      cal () {
        return this.ready ? this.$refs.calendar : null
      },
      nowY () {
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      },
      formattedText () {
        if (this.selectedEvent && this.selectedEvent.text) {
          // console.log('this.selectedEvent')
          // console.log(this.selectedEvent)
          // console.log('this.selectedEvent.text')
          // console.log(this.selectedEvent.text.replace(/\n/g, '<br>'))
          return this.selectedEvent.text.replace(/\n/g, '<br>')
        }
        return ''
      },
      uniqueImagesEvents () {
        const seen = {}
        return this.events.map(item => {
          // Create a new object from the existing one
          const newItem = { ...item }

          // Modify the image_urls property of the new object
          // Extract only the first URL that hasn't been seen before
          newItem.unique_image_urls = item.image_urls.filter((i, index) => {
            if (index === 0 && !Object.prototype.hasOwnProperty.call(seen, i)) {
              seen[i] = true
              return true
            }
            return false
          })

          return newItem
        })
      },
    },
    // マウント時の処理
    mounted: async function () {
      this.ready = true
      this.scrollToTime()
      this.updateTime()
      this.$refs.calendar.checkChange() // カレンダーの更新
      // イベント情報の取得
      await this.fetchEvents()
    },

    // Vueインスタンス作成時の処理
    created: async function () {
      // 日付の初期設定
      this.initDate()
    },
    methods: {
      getCurrentTime () {
        return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
      },
      scrollToTime () {
        const time = this.getCurrentTime()
        const first = Math.max(0, time - (time % 30) - 30)

        this.cal.scrollToTime(first)
      },
      updateTime () {
        setInterval(() => this.cal.updateTimes(), 60 * 1000)
      },
      filterEventsByDC (dc) {
        if (dc === 'All') {
          this.events = this.events_tmp // 全てのイベントを表示
        } else {
          this.events = this.events_tmp.filter(event => event.dc === dc) // 指定されたDCのイベントのみを表示
        }
      },
      openDialog (imageUrl, item) {
        // console.log(item)
        // console.log(item.text.replace(/\n/g, '<br>'))
        this.imgSelectedEvent = item
        this.imgFormattedText = item.text.replace(/\n/g, '<br>')
        this.dialog2 = true // ダイアログを開く
      },
      // イベントの色を取得
      getEventColor (event) {
        switch (event.dc) {
          case 'Mana':
            return 'red'
          case 'Elemental':
            return 'blue'
          case 'Gaia':
            return 'green'
          case 'Meteor':
            return 'orange'
          case 'その他':
            return 'brown'
          default:
            return 'purple'
        }
      },

      redirectToUrl () {
        window.location.href = 'https://twitter.com/extremelalafell/status/1680719672826793984'
      },
      // 日付範囲を「日」表示に変更する
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      // カレンダーを前に進める
      prev () {
        this.$refs.calendar.prev()
      },
      // カレンダーを次に進める
      next () {
        this.$refs.calendar.next()
      },
      // イベント詳細を表示
      showEvent ({ nativeEvent, event }) {
        this.selectedEvent = event
        this.toggleDialog()
        nativeEvent.stopPropagation()
      },
      toggleDialog () {
        this.dialog = !this.dialog
      },
      // カレンダーの表示範囲が変更された時の処理
      updateRange ({ start, end }) {
        // 表示範囲内のイベントのみを絞り込む
        this.filterEventsByDateRange(start, end)
      },
      // 日付の初期設定
      initDate () {
        this.targetDate = new Date()
        this.targetDate = this.formatDate(
          new Date(
            this.targetDate.getFullYear(),
            this.targetDate.getMonth(),
            this.targetDate.getDate() - 2,
          ),
        )
      },
      // 日付をYYYY-MM-DDの書式で返すメソッド
      formatDate (dt) {
        var y = dt.getFullYear()
        var m = ('00' + (dt.getMonth() + 1)).slice(-2)
        var d = ('00' + dt.getDate()).slice(-2)
        return y + '-' + m + '-' + d
      },
      // イベント情報の取得
      fetchEvents: async function () {
        await axios.get(`../../../event/all_users_event.json?timestamp=${new Date().getTime()}`)
          .then(response => {
            // console.log(response) // レスポンスを確認
            try {
              // APIから取得したイベントを整形
              this.formatEvents(response)
              // 初期の範囲に合わせてイベントをフィルター
              this.updateRange({ start: { date: '2023-08-10' }, end: { date: '2023-08-12' } })
            } catch (error) {
              console.error(error)
            }
          })
      },
      // APIから取得したイベントを整形
      formatEvents (response) {
        this.events_tmp = []
        for (const event of response.data) {
          // Check if 'periods' exists and is an array
          if (event.periods && Array.isArray(event.periods)) {
            for (const period of event.periods) {
              this.events_tmp.push({
                timestamp: event.timestamp,
                discord_post_id: event.discord_post_id,
                message_url: event.message_url,
                user_id: event.user_id,
                username: event.username,
                image_urls: event.image_urls,
                text: event.text,
                name: event.event_name,
                start: period.start_time && period.start_time.replace(' ', 'T') + ':00',
                end: period.end_time && period.end_time.replace(' ', 'T') + ':00',
                color: event.color,
                dc: event.dc_name,
              })
            }
          } else if (event.periods && typeof event.periods === 'object') {
            this.events_tmp.push({
              timestamp: event.timestamp,
              discord_post_id: event.discord_post_id,
              message_url: event.message_url,
              user_id: event.user_id,
              username: event.username,
              image_urls: event.image_urls,
              text: event.text,
              name: event.event_name,
              start: event.periods.start_time && event.periods.start_time.replace(' ', 'T') + ':00',
              end: event.periods.end_time && event.periods.end_time.replace(' ', 'T') + ':00',
              color: event.color,
              dc: event.dc_name,
            })
          }
        }
      },

      // イベント詳細を表示するための処理
      openEventDetail (nativeEvent, event) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              this.selectedOpen = true
            })
          })
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              open()
            })
          })
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      // 表示範囲内のイベントのみを絞り込む
      filterEventsByDateRange (start, end) {
        const min = new Date(`${start.date}T00:00:00`)
        this.max = new Date(`${end.date}T00:00:00`)

        // maxに1日加算
        this.max.setDate(this.max.getDate() + 2)
        this.events = this.events_tmp.filter(event => {
          const eventStart = new Date(event.start)
          const eventEnd = new Date(event.end)
          return eventStart >= min && eventEnd <= this.max
        })
        this.events = this.events_tmp
      },
    },
  }
</script>

<style scoped>
.masonryWrap {
  align: center;
  width: 100%;
}

.item-image {
  width: 100%;
  height: auto;
}

.v-current-time {
height: 2px;
background-color: #ea4335;
position: absolute;
left: -1px;
right: 0;
pointer-events: none;

&.first::before {
  content: '';
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}
}
</style>
